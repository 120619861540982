import { Component, OnInit } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-myview-leads',
  templateUrl: './myview-leads.component.html',
  styleUrls: ['./myview-leads.component.css']
})
export class MyviewLeadsComponent implements OnInit {

  getMylead:Array<any> = [];
  constructor(
  private rsbService:RsbService,
  private rsbLookup:RsbLookupModel
) { 
  console.log("mylead")
}

ngOnInit(): void {
  console.log("my lead")
console.log(JSON.stringify(this.getMylead))
console.log(this.rsbLookup.getLoggedUser())
this.getMyleads()
}

getMyleads() {
  this.rsbService.myViewLeads().subscribe((rsp) => {
    if(rsp.statusCode === 0 && rsp.contents) {
    this.getMylead = rsp.contents;
    } else {
      this.getMylead = []
    }
  })

}

}
